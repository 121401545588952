import { signIn } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'

import { serverErrorMessage } from '@/settings/constants'

const useTokenSignin = ({ onSuccess, onFailure, onProcessing }) => {
  const router = useRouter()
  const { token } = router.query
  const signinByToken = useCallback(async () => {
    if (!token) {
      return
    }
    onProcessing()
    try {
      const result = await signIn<'credentials'>('credentials', {
        redirect: false,
        strategy: 'token',
        token,
      })
      if (!result?.ok) {
        return onFailure(result?.error)
      }

      return onSuccess(result)
    } catch (e) {
      onFailure(serverErrorMessage)
    }
  }, [onFailure, onSuccess, token, onProcessing])

  useEffect(() => {
    signinByToken()
  }, [signinByToken])
}

export default useTokenSignin
