import AuthLayout from '@/components/auth/AuthLayout'
import Login from '@/components/auth/Login'

export default function LoginPage(): JSX.Element {
  return <Login />
}

LoginPage.getLayout = function getLayout(page) {
  return <AuthLayout>{page}</AuthLayout>
}

LoginPage.pageName = 'Login'

LoginPage.useRecaptcha = true
