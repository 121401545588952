interface PlanolyProps {
  className?: string
  width?: number
  height?: number
}

export default function Planoly({
  className,
  width = 20,
  height = 20,
}: PlanolyProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.8116 16.3152L14.149 17.0151C14.0773 17.0452 14.0196 17.1018 13.9878 17.1726L13.2491 18.8183C13.1388 19.0636 12.7892 19.0597 12.6848 18.8116L11.9849 17.149C11.9548 17.0773 11.8982 17.0196 11.8274 16.9878L10.1817 16.2491C9.93638 16.1388 9.94031 15.7892 10.1884 15.6848L11.851 14.9849C11.9227 14.9548 11.9804 14.8982 12.0122 14.8274L12.7509 13.1817C12.8612 12.9364 13.2108 12.9403 13.3152 13.1884L14.0151 14.851C14.0452 14.9227 14.1018 14.9804 14.1726 15.0122L15.8183 15.7509C16.0636 15.8612 16.0597 16.2108 15.8116 16.3152Z"
        fill="#EF95CF"
      />
      <path
        d="M13 6.46773C13 2.90148 10.084 0 6.49985 0C5.12459 0 3.84869 0.428495 2.79714 1.15647V0.336592H0V6.46599C0 6.46599 0 6.46715 0 6.46773C0 6.46831 0 6.46889 0 6.46947V18H2.79714V11.779C3.84869 12.507 5.12459 12.9355 6.49985 12.9355C10.084 12.9355 13 10.034 13 6.46773ZM2.79714 6.46947V6.46628C2.79801 4.43542 4.45882 2.78348 6.49985 2.78348C8.54089 2.78348 10.2029 4.43629 10.2029 6.46773C10.2029 8.49917 8.54177 10.152 6.49985 10.152C4.45794 10.152 2.79801 8.50004 2.79714 6.46918V6.46947Z"
        fill="#242424"
      />
    </svg>
  )
}
