import { useQuery } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { signIn, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@snipfeed/forms/V2'
import { Button, Input, Link, useToast } from '@snipfeed/tint2'

import GoogleAuthButton from '@/components/common/GoogleAuthButton'
import Planoly from '@/components/icons/Planoly'
import GET_PROFILE from '@/graphql/queries/profile.gql'
import useTokenSignin from '@/hooks/useTokenSignin'
import { NEXT_AUTH_ERRORS, serverErrorMessage } from '@/settings/constants'

const PlanolyUrl = process.env.NEXT_PUBLIC_PLANOLY_URL

const FormSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1, 'Password is required'),
})

export type LoginFormValues = z.infer<typeof FormSchema>

export default function LoginPage() {
  const router = useRouter()
  const { error } = router.query
  const { status } = useSession()
  const { data: { profile: user } = {} } = useQuery(GET_PROFILE, {
    skip: status !== 'authenticated',
  })

  useEffect(() => {
    if (status === 'authenticated' && user?.id) {
      router.push('/')
    }
  }, [router, status, user?.id])

  const form = useForm<LoginFormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const [isLoading, setIsLoading] = useState(false)

  const { toast } = useToast()

  useTokenSignin({
    onSuccess: useCallback(() => {
      router.replace('/')
    }, [router]),
    onProcessing: useCallback(() => setIsLoading(true), []),
    onFailure: useCallback(
      (error) => {
        setIsLoading(false)
        toast({
          title:
            error?.message || error?.error === 'CredentialsSignin'
              ? 'Wrong Login and Password combination, please try again'
              : serverErrorMessage,
          variant: 'error',
        })
      },
      [toast]
    ),
  })

  const onSubmit = useCallback(
    async (values: LoginFormValues) => {
      setIsLoading(true)

      try {
        const result = await signIn<'credentials'>('credentials', {
          ...values,
          strategy: 'password',
          callbackUrl: '/',
          redirect: true,
        })
        //todo: confirm when redirect is true, result is always undefined when success full
        if (result && !result.ok) {
          throw result
        }
      } catch (e) {
        toast({
          title:
            e?.message || e?.error === 'CredentialsSignin'
              ? 'Wrong Login and Password combination, please try again'
              : serverErrorMessage,
          variant: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    },
    [toast]
  )

  useEffect(() => {
    if (error) {
      toast({
        title: NEXT_AUTH_ERRORS[error as string] ?? NEXT_AUTH_ERRORS.default,
        variant: 'error',
      })
    }
  }, [error, toast])

  return (
    <div className="w-full">
      <h1 className="text-sm mb-8">Welcome back to Snipfeed!</h1>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="mb-2">Email</FormLabel>
                <FormControl>
                  <Input type="email" placeholder="Email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="mb-4">
                <FormLabel className="mb-2">Password</FormLabel>
                <FormControl>
                  <Input type="password" placeholder="Password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Link href="/forgot-password" className="no-underline">
            Forgot your password?
          </Link>

          <Button
            size="md"
            loading={isLoading}
            className="w-full mt-6 h-11 mb-6"
            disabled={!form.formState.isValid}
          >
            Login with email
          </Button>

          <GoogleAuthButton />
          {process.env.NEXT_PUBLIC_SHOW_PLANOLY_LOGO === 'true' && (
            <a
              href={`${PlanolyUrl}/register`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button
                variant="secondary"
                className="w-full mb-6 h-11"
                icon={() => <Planoly className="mr-3" />}
                asChild
              >
                Login with Planoly
              </Button>
            </a>
          )}

          <div className="text-center text-3xsleading-6 text-gray-700 mb-6">
            {`Don't have an account? `}
            <Link href="/signup" className="no-underline">
              Sign up
            </Link>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}
